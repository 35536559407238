@import "../../style/breakpoint.scss";
.productsPage {
  display: flex;
  column-gap: 15px;
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  @include media("<tablet") {
    flex-direction: column;
    row-gap: 15px;
  }
  .productsList {
    width: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    margin-bottom: 0;
    max-height: 511px;
    overflow: auto;
    @include media(">1200px") {
      min-height: 511px;
    }
    @include media("<tablet") {
      flex-direction: column;
      row-gap: 15px;
      width: 95%;
      margin: 0 auto;
      margin-bottom: 15px;
    }
    .titleName {
      padding: 10px 15px;
    }
    .allProductsCards {
      display: flex;
      column-gap: 15px;
      row-gap: 15px;
      padding-left: 15px;
      padding-bottom: 15px;
      flex-wrap: wrap;
      .card__txt {
        max-width: 80%;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        min-height: 50px;
        font-size: 14px;
      }
      button {
        width: 100%;
        color: #fff;
        border: none;
        border-radius: 0px 0px 5px 5px;
        padding: 6px 0;
        background-color: #00a651;
        font-size: 12px;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
