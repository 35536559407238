@import "../../style/breakpoint.scss";
.productCard {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  row-gap: 25px;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  max-width: 23.15%;
  width: 100%;
  padding: 25px 0 0px;
  transition: all 0.3s linear;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
  @include media("<1270px") {
    max-width: 32%;
    row-gap: 15px;
    column-gap: 15px;
  }
  @include media("<1150px") {
    max-width: 45%;
    row-gap: 15px;
    column-gap: 15px;
  }
  @include media("<tablet") {
    max-width: 45%;
    row-gap: 15px;
    column-gap: 15px;
  }
  @include media("<=phone") {
    max-width: 45%;
    margin: unset;
    row-gap: 10px;
    column-gap: 5px;
  }
  .card__imgCvr {
    border: 1px solid #ccc;
    border-radius: 100%;
    width: 120px;
    overflow: hidden;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &:hover {
    transform: none;
  }
}
