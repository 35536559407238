table {
  border-collapse: collapse;
  width: 99.999%;
  th,
  td {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: normal;
    text-align: left;
    vertical-align: top;
  }
  th {
    background-color: #00a651;
    color: #fff;
  }
  td {
    border: 1px solid #f5f5f5;
    P {
      width: 180px;
      margin: 0;
    }
  }
}
