.react-select__value-container {
  background-image: linear-gradient(45deg, transparent 50%, #00a651 50%),
    linear-gradient(135deg, #00a651 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em - 2px),
    calc(100% - 15px) calc(1em - 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  height: 100%;
  max-height: 34px;
  input{
    height: 20px !important;
  }
  .react-select__single-value {
    font-size: 14px;
    text-transform: capitalize;
  }
  .css-1uccc91-singleValue,
  .css-1wa3eu0-placeholder {
    transform: translateY(-58%) !important;
    font-size: 14px;
  }
}
.css-yk16xz-control {
  min-height: 100% !important;
  input {
    height: 20px !important;
  }
}
.react-select__indicators {
  display: none !important;
}
.react-select__control {
  height: 100% !important;
  min-height: 100% !important;
  .css-g1d714-ValueContainer {
    height: 100% !important;
    .css-b8ldur-Input {
      height: 100% !important;
      input:focus {
        height: 20px !important;
      }
    }
  }
}

.react-select__menu-list {
  max-height: 110px !important;
  overflow-y: auto !important;
  .react-select__option {
    padding: 3px 10px;
    font-size: 14px;
    text-transform: capitalize;
  }
  .react-select__option--is-focused {
    color: #fff;
  }
}
