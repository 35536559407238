@import "../../style/breakpoint.scss";
header {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0px 1px 4px #00000029;
  max-height: 80px;
  z-index: 1050;
  padding: 10px 0;
  .headerCover {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    @include media("<=tablet") {
      padding: 0 15px;
    }
    @include media("<=520px") {
      padding: 0 7px;
    }
    .leftSection {
      display: flex;
      column-gap: 20px;
      align-items: center;
      @include media("<=phone") {
        column-gap: 10px;
      }
      .totalELogo {
        max-width: 140px;
        @include media("<=520px") {
          max-width: 110px;
        }
        @include media("<=380px") {
          max-width: 80px;
        }
      }
      .img-cvr {
        position: relative;
        @include media("<=phone") {
          display: flex;
        }
        img {
          cursor: pointer;
          @include media("<=520px") {
            max-width: 110px;
          }
          @include media("<=380px") {
            max-width: 80px;
          }
        }
        &:after {
          content: "";
          position: absolute;
          top: 0px;
          right: -5px;
          width: 1px;
          height: 100%;
          background-color: #ccc;
          @include media("<=phone") {
            height: 25px;
          }
        }
      }
    }
    .rightSection {
      display: flex;
      column-gap: 20px;
      align-items: center;
      @include media("<=660px") {
        column-gap: 5px;
      }
      .helplineNumber {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #00a651;
        padding: 7px 15px;
        border-radius: 21px;
        padding: 6px 15px;
        column-gap: 10px;
        @include media("<=520px") {
          padding: 3px 5px;
        }
        img {
          width: 18px;
          @include media("<=520px") {
              width: 12px;
            }
          @include media("<=380px") {
            width: 10px;
          }
        }
        .numberDetailsBox {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .numberText {
            color: #fff;
            font-size: 11px;
            @include media("<=520px") {
              font-size: 9px;
            }
            @include media("<=380px") {
              font-size: 7px;
            }
          }
          .number {
            color: #ffcf14;
            font-size: 11px;
            @include media("<=520px") {
              font-size: 10px;
            }
            @include media("<=380px") {
              font-size: 9px;
            }
          }
        }
      }
      .logout {
        display: flex;
        @include media("<=660px") {
          display: none;
        }
        img {
          max-width: 25px;
          cursor: pointer;
          height: 25px;
        }
      }
      .setting {
        display: flex;
        column-gap: 10px;
        border: 0.5px solid #00a651;
        border-radius: 21px;
        padding: 9px 13px;
        cursor: pointer;
        @include media("<=660px") {
          border: none;
          border-radius: 0;
          padding: 0;
        }
        img {
          @include media("<=660px") {
            max-width: 100%;
            width: 20px;
          }
        }
        span {
          font-size: 14px;
          font-weight: 400;
          @include media("<=660px") {
            display: none;
          }
        }
      }
    }
  }
}
