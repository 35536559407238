@import "../../style/breakpoint.scss";
.locationAllow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  font-size: 25px;
  font-weight: 600;
  color: orange;
  text-align: center;
}
.Weather {
  position: relative;
  height: 83vh;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: url(../../assets/images/loginBanner.png) no-repeat;
    background-origin: border-box;
    background-position-x: right;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    z-index: -1;
  }
}
.Weather-page {
  display: flex;
  row-gap: 15px;
  max-width: 1150px;
  width: 100%;
  margin: auto;
  align-items: center;
  min-height: 73.5vh;
  @include media("<=990px") {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }
  &__cover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    display: flex;
    column-gap: 50px;
    row-gap: 15px;
    padding: 50px 30px 60px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 15px;
    @include media("<=990px") {
      padding: 15px;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  .left-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 15px;
    max-width: 20%;
    width: 100%;
    @include media("<=990px") {
      max-width: 100%;
    }
    .top-box {
      text-align: center;
      font-size: 17px;
      margin-bottom: 20px;
      .top {
        font-size: 30px;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
    .bottom-box {
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      .top {
        font-size: 37px;
      }
      .maxWidthP {
        max-width: 200px;
      }
    }
  }
  .right-box {
    max-width: 75%;
    width: 100%;
    @include media("<=990px") {
      max-width: 100%;
    }
    .top-box {
      display: flex;
      column-gap: 20px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      font-size: 16px;
      .left-child-box {
        display: flex;
        align-items: center;
        column-gap: 10px;
        p {
          max-width: 500px;
        }
      }
    }
  }
}
