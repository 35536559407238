@import "../../style/breakpoint.scss";

.setting-page {
  display: flex;
  row-gap: 15px;
  max-width: 1150px;
  width: 100%;
  margin: auto;
  align-items: center;
  min-height: 73.5vh;
  @include media("<tablet") {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }
  &__cover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 25px 15px 30px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 15px;
    @include media("<tablet") {
      padding: 15px;
    }
    form {
      display: flex;
      column-gap: 10px;
      flex-wrap: wrap;
      row-gap: 15px;
      font-size: 16px;
      @include media("<tablet") {
        font-size: 14px;
      }
      @include media("<phone") {
        font-size: 12px;
      }
      input {
        height: 36px;
        &:disabled {
          background-color: hsl(0, 0%, 95%);
          border-color: hsl(0, 0%, 90%);
        }
      }
      .react-select-container {
        input {
          height: auto;
        }
      }
      h1 {
        width: 100%;
        font-size: 18px;
        color: #00a651;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .editButton {
          display: flex;
          column-gap: 10px;
          background-color: #00a651;
          border-radius: 5px;
          padding: 10px 13px;
          color: #fff;
          font-size: 14px;
          font-weight: 400;
          text-transform: none;
          cursor: pointer;
        }
      }
      .form-group {
        width: 49%;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        position: relative;
        @include media("<tablet") {
          width: 100%;
        }
        .error {
          font-size: 12px;
          color: red;
          position: absolute;
          bottom: -15px;
          left: 0;
          margin: 0;
        }
      }
      .cropBoxCover {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .cropBoxCoverInner {
          display: flex;
          column-gap: 10px;
          width: 100%;
          @include media("<tablet") {
            flex-direction: column;
            row-gap: 10px;
          }
        }
        .cropBox {
          width: 49%;
          @include media("<tablet") {
            width: 100%;
          }
        }
      }
      .btn-cvr {
        margin-top: 20px;
        button {
          width: 100%;
          background-color: #00a651;
          border-radius: 5px;
          padding: 10px 20px;
          color: #fff;
          border: none;
          box-shadow: none;
          text-transform: uppercase;
          cursor: pointer;
          &:disabled {
            background-color: hsl(0, 0%, 95%);
            border-color: hsl(0, 0%, 90%);
            color: rgba(204, 187, 187, 0.5);
          }
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
