/* Loader css*/

.loading-box {
  z-index: 999;
  background: rgba(79, 73, 79, 0.6);
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 0;
  top: 0;
}
.loader-logo {
  position: absolute;
  top: 50%;
  margin-top: -26px;
  z-index: 999;
  left: 50%;
  margin-left: -34px;
  width: 70px;
}
.loader {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  bottom: 0;
  margin: -35px 0 0 -35px;
  background: #fff;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.offlineMode {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 50vh;
}
.offlineMode h1 {
  font-size: 30px;
  color: red;
  text-align: center;
}

.modalCloseButton {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #00a651;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 4;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.modalCover {
  position: relative;
}
