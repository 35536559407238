@import "../../style/breakpoint.scss";
.pageName {
  max-width: 1150px;
  width: 100%;
  margin: 0 auto;
  @include media("<=tablet") {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }
  a {
    color: #000;
    font-size: 12px;
    display: flex;
    cursor: pointer;
  }
  .MuiBreadcrumbs-separator {
    margin: 0 2px 0 3px;
  }
  p {
    font-size: 12px;
  }
  .MuiBreadcrumbs-ol {
    li {
      &:last-child {
        a {
          font-weight: bold;
          cursor: text;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}
