@import "../../style/breakpoint.scss";
.react-tabs {
  padding-left: 10px;
}
.react-tabs__tab-list {
  border-bottom: none;
  max-width: 60%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @include media("<tablet") {
    max-width: 65%;
  }
  @include media("<phone") {
    max-width: 100%;
  }
  .react-tabs__tab {
    line-height: 1;
    padding: 9px 12px;
    font-size: 14px;
    &:focus::after {
      display: none;
    }
  }
  .react-tabs__tab--selected {
    background-color: #00a651;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    color: #fff;
    border: none;
  }
}
.react-tabs__tab-panel {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  background-color: #fbfbfb;
  padding: 15px;
  overflow: auto;
}

.react-tabs__tab-panel--selected {
  display: flex;
  column-gap: 10px;
  align-items: center;
  row-gap: 10px;
  @include media("<tablet") {
    flex-wrap: wrap;
    flex: 1 1 auto;
    justify-content: stretch;
  }
  .weatherCard {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    font-size: 12px;
    border-radius: 5px;
    min-width: 120px;
    @include media("<tablet") {
      flex: 1 0 150px;
    }
    &:hover {
      background-color: #00a651;
      color: #fff;
      box-shadow: 0px 3px 6px #00000029;
    }
    img {
      max-width: 100px;
      width: 100%;
    }
  }
}
