.notFound {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;
  height: 85%;
}
.notFound h1 {
  font-size: 100px;
  color: #00a651;
  font-weight: 700;
}

@media (max-width: 767px) {
  .notFound h1 {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.notFound p {
  font-size: 20px;
  color: #000;
  font-weight: 300;
  text-align: center;
}
.notFound p.firstP {
  font-size: 40px;
}

.notFound button {
  padding: 8px 20px;
  background-color: #00a651;
  border: none;
  font-size: 16px;
  margin-top: 20px;
  color: white;
  cursor: pointer;
}
