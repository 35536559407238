@import '../../style/breakpoint.scss';
.login-page {
  display: flex;
  height: 100vh;
  @include media('<=767px') {
    flex-direction: column;
    position: relative;
  }
  &__left {
    background: url(../../assets/images/loginBanner.png) no-repeat;
    background-size: cover;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media('<=767px') {
      width: 100%;
      height: 100vh;
      align-items: flex-start;
      padding-top: 30px;
    }
    img {
      @include media('<=767px') {
        max-width: 100px;
      }
    }
  }
  &__right {
    width: 50%;
    display: flex;
    position: relative;
    align-items: center;
    @include media('<=767px') {
      width: 100%;
      position: unset;
    }
    .contentBox {
      background-color: var(--text__color--white);
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 10px;
      position: absolute;
      left: -20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 720px;
      width: 100%;
      @include media('<=767px') {
        width: 90%;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 150px;
      }
    }
  }
}
