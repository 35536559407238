@import "../../style/breakpoint.scss";
.slick-slider {
  .slick-slide.slick-active.slick-current {
    z-index: 9;
  }
  .slick-prev {
    left: 0;
    z-index: 1;
  }
  .slick-next {
    right: 17px;
    z-index: 1;
  }
  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 35px;
  }
  img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .slick-dots {
    bottom: 5%;
    width: 100%;
    li {
      width: 10px;
      height: 10px;

      &.slick-active {
        button:before {
          border: 1px solid #ccc;
        }
      }
      button {
        width: 10px;
        height: 10px;
        border: 1px solid #ccc;
        border-radius: 100%;
        background: #ccc;

        &:before {
          content: "";
          width: 100%;
          height: 10px;
          background-color: #00a651;
          border: 1px solid #ccc;
          border-radius: 100%;
        }
      }
    }
  }
}
.homeContent {
  display: flex;
  align-items: center;
  column-gap: 15px;
  row-gap: 15px;
  max-width: 1150px;
  margin: 0 auto;
  width: 100%;
  padding: 15px 0 15px;
  flex-wrap: wrap;
  margin-bottom: 50px;
  @include media("<tablet") {
    row-gap: 20px;
    width: 95%;
  }
  .card {
    &:last-child {
      display: none;
    }
  }
}

.home {
  .slick-slider {
    img {
      max-height: 350px;
      object-fit: contain;
      object-position: top;
    }
  }
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.9) !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reactPlayerCover {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 42%;
  outline: none;
  @include media("<tablet") {
    padding-bottom: 42%;
  }
  iframe,
  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: contain !important;
  }
  ._53mv {
    object-fit: contain !important;
  }
}
