@import '../../style/breakpoint.scss';
:root {
  --text__color--dark: #000;
  --text__color--light: #a5a5a5;
  --text__color--white: #fff;
}
.registration-form {
  padding: 20px 0 30px;
  width: 100%;
  max-height: 550px;
  overflow: auto;
  h1 {
    font-size: 18px;
    text-align: center;
    margin-top: 0;
    margin-bottom: 5px;
  }
  form {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
    @include media('<=560px') {
      width: 90%;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
      position: relative;
      label {
        font-size: 14px;
        @include media('<=560px') {
          font-size: 12px;
        }
      }
      .error {
        font-size: 12px;
        color: red;
        position: absolute;
        bottom: -15px;
        left: 0;
        margin: 0;
      }
      input[type='text'] {
        height: 35px;
      }
    }
    .cropBoxCover {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }
  .btn-cvr {
    margin-top: auto;
    button {
      width: 100%;
      background-color: #00a651;
      border-radius: 5px;
      padding: 13px 0;
      color: #fff;
      border: none;
      box-shadow: none;
      text-transform: uppercase;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}
