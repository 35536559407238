input {
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 14px;
  padding: 12px 10px;
  box-sizing: border-box;
  &:focus {
    outline-color: #00a651;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
