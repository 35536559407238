@import "../../style/breakpoint.scss";
.sidebar {
  min-width: 250px;
  position: relative;
  @include media("<tablet") {
    width: 95%;
    margin: 0 auto;
  }
  .hamburger-menu {
    cursor: pointer;
    display: none;
    position: fixed;
    top: 60px;
    height: 40px;
    left: 0;
    z-index: 9999;
    @include media("<tablet") {
      display: block;
    }
    img {
      width: 33px;
      height: 33px;
    }
  }
  .menu {
    background-color: #00a651;
    border: 1px solid #e7e7e7;
    border-radius: 5px;
    font-size: 14px;
    min-height: 78vh;
    @include media("<1240px") {
      min-height: 89vh;
    }
    @include media("<tablet") {
      min-height: auto;
      display: none;
    }
    .menuHeading {
      padding-left: 15px;
      color: #fff;
      font-weight: bold;
      padding-top: 15px;
      padding-bottom: 8px;
    }
    ul {
      margin: 0;
      list-style: none;
      padding: 0;
      li {
        a {
          color: #fff;
          text-decoration: none;
          width: auto;
          padding: 8px;
          padding-left: 15px;
          display: flex;
          position: relative;
          cursor: pointer;
          &.active,
          &:hover {
            &:before {
              position: absolute;
              content: "";
              width: 2px;
              height: 100%;
              background-color: #fff;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
  .searchSection {
    position: relative;
    input {
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      background-color: #fafafa;
      height: 35px;
      width: 260px;
      @include media("<tablet") {
        width: 100%;
      }
    }
    img {
      position: absolute;
      right: 11px;
      top: 9px;
      cursor: pointer;
    }
  }
}
