@import '../../style/breakpoint.scss';
.loginBox {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 50px;
  max-width: 535px;
  width: 100%;
  height: 100%;
  text-align: center;
  h1 {
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  p {
    font-size: 16px;
    color: #000;
    &.lightText {
      color: #a5a5a5;
      font-size: 14px;
      margin-top: 3px;
    }
  }
  input {
    margin-bottom: 0px;
  }
  form {
    display: flex;
    flex-direction: column;
    height: 350px;
    margin-top: 40px;
    @include media('<=560px') {
      width: 90%;
      margin: 0 auto;
      margin-top: 40px;
      height: 200px;
    }
    label {
      text-align: left;
      margin-bottom: 10px;
      font-size: 14px;
      span {
        color: red;
      }
    }
    .error {
      color: red;
      font-size: 14px;
      width: 100%;
      text-align: left;
    }
    button {
      width: 100%;
      background-color: #00a651;
      border-radius: 5px;
      padding: 13px 0;
      color: #fff;
      border: none;
      box-shadow: none;
      text-transform: uppercase;
      cursor: pointer;
      margin-top: auto;
      &:focus {
        outline: none;
      }
    }
  }
}
