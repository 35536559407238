@import "../../style/breakpoint.scss";
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  max-width: 23.8%;
  width: 100%;
  padding: 15px 0;
  transition: all 0.3s linear;
  font-weight: 700;
  cursor: pointer;
  @include media("<tablet") {
    max-width: 48%;
    row-gap: 15px;
    column-gap: 15px;
  }
  @include media("<=phone") {
    max-width: 46%;
    margin: 0 auto;
    row-gap: 10px;
  }
  &__imgCvr {
    border: 1px solid #ccc;
    border-radius: 100%;
    width: 90px;
    img {
      width: 100%;
    }
  }
  &:hover {
    transform: scale(1.05);
  }
}
