.cropBox {
  display: flex;
  column-gap: 10px;
  align-items: flex-end;
  img {
    margin-bottom: 5px;
    cursor: pointer;
  }
  .form-group {
    width: 48%;
  }
}
.inputTopMar {
  margin-top: 15px;
}
