@import "../../style/breakpoint.scss";
:root {
  --text__color--dark: #000;
  --text__color--light: #a5a5a5;
  --text__color--white: #fff;
}
p {
  margin: 0;
}
.termOfUse {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  .totalE-logo {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    img {
      max-width: 300px;
    }
  }
  &__text {
    height: 420px;
    margin-bottom: 0;
    padding: 0 20px;
    font-size: 14px;
    max-height: 400px;
    overflow: auto;
    h2 {
      font-size: 18px;
      text-align: center;
    }
    .numberList {
      padding-left: 15px;
      margin: 0;
      li {
        margin-bottom: 10px;
      }
      ol {
        list-style-type: lower-latin;
        padding-left: 15px;
        padding-top: 5px;
      }
      ul {
        padding-left: 15px;
        padding-top: 5px;
        li {
          margin-bottom: 10px;
        }
      }
    }
    object {
      padding-left: 35px;
      @include media("<=1260px") {
        padding-left: 0px;
      }
    }
  }
  .radioGroupCover {
    display: flex;
    justify-content: flex-start;
    width: 94%;
    margin-bottom: 10px;
    .error {
      color: red;
      font-size: 12px;
      font-weight: 500;
    }
  }
}
