@import "../../style/breakpoint.scss";
.Pdf {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: url(../../assets/images/loginBanner.png) no-repeat;
    background-origin: border-box;
    background-position-x: right;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    z-index: -1;
  }
}
.Pdf-page {
  display: flex;
  row-gap: 15px;
  max-width: 1150px;
  width: 100%;
  margin: auto;
  align-items: center;
  min-height: 73.5vh;
  @include media("<=990px") {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }
  &__cover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    display: flex;
    column-gap: 50px;
    row-gap: 15px;
    padding: 10px;
    width: 100%;
    height: 73vh;
    background-color: #fff;
    margin-bottom: 30px;
    @include media("<=990px") {
      padding: 15px;
      flex-direction: column;
      row-gap: 20px;
      height: 100vh;
    }
  }
}
