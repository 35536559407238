@import "../../style/breakpoint.scss";
.ProductDetails-page {
  margin-bottom: 70px;
  @include media("<510px") {
    width: 90%;
    margin: 0 auto;
  }
  .productInfo {
    max-width: 1150px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    column-gap: 20px;
    @include media("<510px") {
      flex-direction: column;
      row-gap: 20px;
    }
    button {
      color: #fff;
      border: none;
      border-radius: 2px;
      padding: 6px 20px;
      background-color: #00a651;
      font-size: 12px;
      height: 30px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    &__left {
      border: 1px solid #eaeaea;
      max-width: 35%;
      padding: 30px 0 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      @include media("<510px") {
        max-width: 100%;
      }
      img {
        margin-bottom: 20px;
        max-width: 100%;
        max-height: 200px;
      }
      .headPhoneIcon {
        position: absolute;
        right: 15px;
        top: 15px;
        margin: 0;
        cursor: pointer;
      }
      .pdfIcon {
        position: absolute;
        right: 15px;
        bottom: 15px;
        margin: 0;
        cursor: pointer;
        width: 45px;
        height: 45px;
      }
      .detailsBox {
        margin-bottom: 20px;
        font-size: 12px;
        text-align: center;
        p {
          color: #000;
          margin-bottom: 0;
          font-weight: 400;
          font-size: 12px;
          &:first-child {
            color: #00a651;
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 0;
          }
        }
        ul {
          padding: 0;
          margin: 0;
          margin-top: 5px;
          padding-left: 17px;
          list-style: none;
          li {
            font-size: 12px;
            text-align: center;
            margin-bottom: 4px;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 4px;
        margin-top: 0;
        &:nth-of-type(1) {
          font-weight: 700;
        }
        &:nth-of-type(2) {
          margin-bottom: 15px;
          font-size: 12px;
        }
      }
    }
    &__right {
      .detailsBox {
        margin-bottom: 15px;
        span {
          display: flex;
          row-gap: 10px;
          font-size: 12px;
        }
        .widthSet {
          max-width: 260px;
        }
        ul {
          padding: 0;
          margin: 0;
          padding-left: 17px;
          li {
            font-size: 12px;
            &::marker {
              color: #00a651;
            }
          }
        }
        p {
          font-size: 12px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 2px;
          max-width: 570px;
          &:first-child {
            color: #00a651;
            font-size: 14px;
            font-weight: bold;
          }
          &:last-child {
            color: #000000;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .tableDetails {
    max-width: 1150px;
    margin: 0 auto;
    margin-bottom: 20px;
    width: 100%;
    .tableTitle {
      margin-bottom: 10px;
      color: #00a651;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .table-responsive {
      overflow: auto;
    }
  }
}
