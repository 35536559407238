@import "../../style/breakpoint.scss";
.SymptomsAndSolutions-page {
  margin-bottom: 50px;
  @include media("<phone") {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 70px;
  }
  .symSolTitle {
    max-width: 1150px;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .solTitle {
    max-width: 1150px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
  }
  .slider-cover {
    max-width: 1150px;
    margin: 0 auto;
    .slider-card {
      &:focus {
        outline: none;
      }
    }
    .slider-card-cover {
      display: flex;
      align-items: center;
      padding: 40px 100px;
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      background-color: #f9f9f9;
      column-gap: 30px;
      @include media("<tablet") {
        padding: 20px 20px 40px;
      }
      @include media("<phone") {
        flex-direction: column;
        row-gap: 20px;
      }
      &__left {
        img {
          max-width: unset;
          width: 200px;
          height: 200px;
          border-radius: 100%;
        }
      }
      &__right {
        text-align: center;
        width: 100%;
        p {
          font-size: 14px;
          max-width: 650px;
          margin: 0 auto;
        }
        .txtHeader {
          font-size: 16px;
          font-weight: bold;
          text-transform: uppercase;
          margin-bottom: 20px;
        }
      }
    }
    .slick-dots {
      @include media("<phone") {
        bottom: -7px;
      }
    }
    .slick-arrow {
      z-index: 11;
      color: #000 !important;
      bottom: 5px;
      top: unset !important;
      transform: unset !important;
      &.slick-prev {
        left: 12px;
        @include media("<phone") {
          bottom: -15px;
        }
      }
      &.slick-next {
        right: 50px;
        @include media("<phone") {
          bottom: -15px;
        }
      }
      &::before {
        display: none;
      }
      .prev-slick-arrow,
      .next-slick-arrow {
        display: flex;
        column-gap: 10px;
        align-items: center;
      }

      span {
        display: flex;
        font-size: 14px !important;
      }
    }
  }
  .productInfo {
    max-width: 1150px;
    margin: 20px auto;
    width: 100%;
    display: flex;
    column-gap: 20px;
    @include media("<phone") {
      flex-direction: column;
      row-gap: 20px;
    }
    button {
      color: #fff;
      border: none;
      border-radius: 2px;
      padding: 6px 20px;
      background-color: #00a651;
      font-size: 12px;
      height: 30px;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
    &__left {
      border: 1px solid #eaeaea;
      background-color: #f0f7f3;
      max-width: 30%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include media("<phone") {
        max-width: 100%;
      }
      img {
        max-width: 100%;
        max-height: 80%;
        object-fit: unset;
      }
      p {
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    .detailsBox {
      margin-bottom: 12px;
      p {
        color: #00a651;
        font-size: 14px;
        font-weight: bold;
        margin: 0;
        margin-bottom: 2px;
        max-width: 570px;
        &:last-child {
          color: #000000;
          font-size: 12px;
          font-weight: 400;
        }
      }
      ul {
        padding-left: 20px;
        li {
          color: #000000;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
}

.symSlider {
  img {
    max-height: 90vh;
  }
}
