@import "../../style/breakpoint.scss";
.askAnExpert {
  position: relative;
  height: 83vh;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
    background: url(../../assets/images/loginBanner.png) no-repeat;
    background-origin: border-box;
    background-position-x: right;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    z-index: -1;
  }
}
.askAnExpert-page {
  display: flex;
  row-gap: 15px;
  max-width: 1150px;
  width: 100%;
  margin: auto;
  align-items: center;
  min-height: 73.5vh;
  @include media("<tablet") {
    max-width: 90%;
    width: 100%;
    margin: 0 auto;
  }
  &__cover {
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 20px 15px;
    width: 100%;
    background-color: #fff;
    margin-bottom: 15px;
    @include media("<tablet") {
      padding: 15px;
    }
  }

  .topBox {
    display: flex;
    column-gap: 5px;
    padding: 17px 0 13px;
    align-items: center;
    @include media("<tablet") {
      width: 100%;
      margin: 0 auto;
    }
    &__left {
      display: flex;
    }
    &__right {
      display: flex;
      flex-direction: column;
      .txt {
        color: #00a651;
        font-size: 16px;
        margin: 0;
        font-weight: bold;
        text-transform: uppercase;
      }
      .phoneNumber {
        color: #000000;
        font-size: 32px;
        margin: 0;
        font-weight: 400;
        @include media("<phone") {
          font-size: 25px;
        }
      }
    }
  }
  .middleBox {
    display: flex;
    column-gap: 15px;
    row-gap: 15px;
    @include media("<tablet") {
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
    }
    p {
      margin: 0;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      row-gap: 2px;
      &.addressHeading {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    &__left {
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      background-color: #fbfbfb;
      padding: 10px 15px;
      width: 49%;
      @include media("<tablet") {
        width: auto;
      }
    }
    &__right {
      border: 1px solid #e7e7e7;
      border-radius: 5px;
      background-color: #fbfbfb;
      padding: 10px;
      width: 49%;
      span {
        word-break: break-all;
        a {
          color: #000;
          display: flex;
        }
      }
      @include media("<tablet") {
        width: auto;
      }
    }
  }
  .bottomBox {
    margin-bottom: 13px;
    @include media("<tablet") {
      max-width: 90%;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
    img {
      width: 100%;
      max-width: 100%;
    }
  }
}
