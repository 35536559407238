@import "../../style/breakpoint.scss";
footer {
  background-color: #505050;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 7px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  flex-direction: column;
  z-index: 11;
  P {
    font-size: 12px;
    color: #fff;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footerLink {
    justify-content: center;
    font-size: 12px;
    color: #ffcf14;
    display: flex;
    column-gap: 5px;
    width: 100%;
    span {
      cursor: pointer;
      display: flex;
    }
    .footerChangeLang {
      max-width: 110px;
      display: block;
      width: 100%;
      input {
        height: auto !important;
        padding: 0 !important;
        margin: 0 !important;
      }
      .css-b8ldur-Input {
        padding: 0 !important;
        margin: 0 !important;
      }
      .react-select__control {
        border: none;
        background-color: transparent;
        min-height: auto !important;
        box-shadow: none !important;
      }
      .react-select__value-container {
        background-image: linear-gradient(45deg, transparent 50%, #ffcf14 50%),
          linear-gradient(135deg, #ffcf14 50%, transparent 50%);
        background-position: calc(100% - 5px) calc(1em - 4px),
          calc(100% - 0px) calc(1em - 4px), calc(100% - 2.5em) 0.5em;
        background-size: 3px 3px, 5px 4px, 1px 1.5em;
        background-repeat: no-repeat;
        padding: 0;
        .css-1g6gooi {
          margin: 0 !important;
          padding: 0 !important;
        }
        .react-select__placeholder,
        .css-1uccc91-singleValue {
          color: #ffcf14;
          font-size: 12px;
          margin: 0;
          transform: translateY(-50%) !important;
        }
        .react-select__input {
          input:focus {
            height: auto !important;
            border: none !important;
          }
        }
      }
    }
  }
}
