@import "../../style/breakpoint.scss";
.OTPBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 50px 0;
  height: 480px;
  @include media("<=560px") {
    height: 300px;
  }
  .otp-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 30px 0;
    height: 480px;
  }
  .otpHeading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 40px;
    h1 {
      font-size: 18px;
      text-transform: uppercase;
      margin-bottom: 5px;
      margin-top: 0;
    }
    p {
      font-size: 16px;
    }
  }
  .otpInputs {
    p {
      color: #a5a5a5;
      font-size: 14px;
      margin-bottom: 10px;
    }
    input.otp-field {
      max-width: 80px;
      width: 100%;
      height: 42px;
      border-radius: 0;
      padding: 0;
      text-align: center;
      border-width: 1px;
      @include media("<=1260px") {
        max-width: 60px;
      }
      @include media("<=660px") {
        max-width: 40px;
        height: 35px;
      }
      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      &:nth-of-type(1) {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    }
  }
  .resendMsg {
    font-size: 12px;
    margin-top: auto;
    @include media("<=960px") {
      margin-bottom: 50px;
    }
  }
  .resendOTP {
    font-size: 14px;
    color: #00a651;
    border: 1px solid #00a651;
    border-radius: 5px;
    padding: 5px 10px;
    max-width: 100px;
    margin: 0 auto;
    margin-top: auto;
    cursor: pointer;
    @include media("<=960px") {
      margin-bottom: 50px;
    }
  }
  .error {
    color: red;
    font-size: 14px;
    width: 100%;
    text-align: left;
  }
  .btn-cvr {
    margin-top: auto;
    button {
      width: 100%;
      background-color: #00a651;
      border-radius: 5px;
      padding: 13px 0;
      color: #fff;
      border: none;
      box-shadow: none;
      text-transform: uppercase;
      cursor: pointer;
      &:focus {
        outline: none;
      }
    }
  }
}
